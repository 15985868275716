<template>
  <div>
    <el-form :model="form"
             label-width="80px">
      <el-row :gutter="10"
              style="display:flex;flex-wrap:wrap">
        <el-col :lg="6"
                :md="12"
                v-if="home_id == 1 "
                :sm="12"
                :xl="6"
                :xs="24">
          <el-form-item label="学校:">
            <el-select v-model="form.school_id"
                       filterable
                       clearable
                       style="width:100%"
                       @clear="schoolChange"
                       placeholder="选择学校">
              <el-option v-for="item in schoolList"
                         :key="item.school_id"
                         :label="item.school_name"
                         :value="item.school_id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :lg="6"
                :md="12"
                :sm="12"
                :xl="6"
                :xs="24">
          <el-form-item label="届:">
            <el-select v-model="form.graduated_id"
                       style="width:100%"
                       clearable
                       filterable
                       
                       placeholder="选择届">
              <el-option v-for="item in jieList"
                         :key="item.graduated_id"
                         :label="item.year_desc"
                         :value="item.graduated_id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :lg="6"
                :md="12"
                :sm="12"
                :xl="6"
                :xs="24">
          <el-form-item label="年级:">
            <el-select v-model="form.grade_id"
                       style="width:100%"
                       clearable
                       placeholder="选择年级"
                       @change="getClassList">
              <el-option v-for="item in gradeList"
                         :key="item.grade_id"
                         :label="item.grade_name"
                         :value="item.grade_id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :lg="6"
                :md="12"
                :sm="12"
                :xl="6"
                :xs="24">
          <el-form-item label="班级:">
            <el-select v-model="form.special_class_ids"
                       style="width:100%"
                       clearable
                       multiple
                       filterable
                       placeholder="选择班级">
              <el-option v-for="item in classList"
                         :key="item.id"
                         :label="item.class_name"
                         :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :lg="6"
                :md="12"
                :sm="12"
                :xl="6"
                :xs="24">
          <el-form-item label="用户:">
            <el-input clearable
                      placeholder="用户姓名或手机"
                      v-model="form.name_or_mobile"
                      style="width:100%" />
          </el-form-item>
        </el-col>
        <el-col :lg="6"
                :md="12"
                :sm="12"
                :xl="6"
                :xs="24">
          <el-form-item label="开始时间:">
            <el-date-picker v-model="form.timeBegin"
                            style="width:100%"
                            @focus="$forbid"
                            type="datetime"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            placeholder="开始时间" />
          </el-form-item>
        </el-col>
        <el-col :lg="6"
                :md="12"
                :sm="12"
                :xl="6"
                :xs="24">
          <el-form-item label="截止时间:">
            <el-date-picker v-model="form.timeEnd"
                            style="width:100%"
                            @focus="$forbid"
                            type="datetime"
                            default-time="23:59:59"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            placeholder="截止时间" />
          </el-form-item>
        </el-col>
        <el-col :lg="6"
                :md="12"
                :sm="12"
                v-if="showUserTypeSelect"
                :xl="6"
                :xs="24">
          <el-form-item label="改卷类型:">
            <el-select v-model="form.correct_type"
                       style="width:100%"
                       clearable
                       placeholder="改卷类型">
              <el-option v-for="item in correctTypeList"
                         :key="item.value"
                         :label="item.label"
                         :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :lg="6"
                :md="12"
                v-if="showUserTypeSelect"
                :sm="12"
                :xl="6"
                :xs="24">
          <el-form-item label="用户类型:">
            <el-select v-model="form.user_type"
                       style="width:100%"
                       clearable
                       filterable
                       placeholder="用户类型">
              <el-option v-for="item in userTypeList"
                         :key="item.user_type_id"
                         :label="item.user_type_name"
                         :value="item.user_type_id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>

      </el-row>
      <el-row :gutter="10">
        <el-col :span="24">
          <el-form-item label-width="30px">
            <el-button type="primary"
                       @click="search">提交</el-button>
            <el-button type="primary"
                       @click="exportExcel">导出</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-row :gutter="10"
            style="padding-bottom:10px">
      <el-col :span="24"
              style="font-size:14px">
        <div>
          <div>
            (说明：1. 请先选择学校，然后再选择届、班。学校的学生全部毕业了的不能统计。班在届后；
          </div>
          <div>
            2.“用户”可以模糊输入用户名或手机号。 3. 默认开始时间为当前时间的前7天，默认截止时间是当前时间。 ）
          </div>
        </div>
      </el-col>
    </el-row>
    <el-table border
              stripe
              :data="tableData"
              :summary-method="getSummaries"
              show-summary
              highlight-current-row
              :max-height="$store.state.tableHeight"
              style="width: 100%">
      <el-table-column prop="truename"
                       align="center"
                       label="学生名称" />
      <el-table-column prop="verifiedMobile"
                       align="center"
                       label="手机号" />
      <el-table-column prop="correct_type"
                       align="center"
                       label="改卷类型">
        <template slot-scope="{row}">
          {{correctType[row.correct_type]}}
        </template>
      </el-table-column>
      <el-table-column prop="graduate_year"
                       align="center"
                       label="届" />
      <el-table-column prop="class_name"
                       align="center"
                       show-overflow-tooltip
                       label="班级" />
      <el-table-column prop="count_all"
                       align="center"
                       label="总提交试卷数" />
      <el-table-column prop="zhengjuan_count"
                       align="center"
                       label="提交整卷测试数" />
      <!-- 字段没改 -->
      <el-table-column prop="knowledge_count"
                       align="center"
                       label="考点训练数量" />
      <el-table-column prop="teacher_count"
                       align="center"
                       label="精选试卷数" />
      <el-table-column prop="count_submitted_with_image"
                       align="center"
                       label="提交带图像(需要批改)试卷数" />
      <el-table-column prop="count_checked"
                       align="center"
                       label="已全部批改试卷数" />
      <el-table-column prop="free_count"
                       align="center"
                       label="提交自由选题数" />
      <el-table-column prop="chapter_count"
                       align="center"
                       label="提交章节训练数" />
      <!-- <el-table-column prop="knowledge_count"
                       align="center"
                       label="提交考点训练数" /> -->
      <el-table-column prop="ave"
                       align="center"
                       label="提交试卷平均分" />
      <el-table-column prop="userScoreRate"
                       align="center"
                       label="学生得分率" />
      <el-table-column align="center"
                       width="200"
                       fixed="right"
                       label="操作">
        <template slot-scope="{row}">
          <div class="text_btn_primary opertion_font"
               @click="toInfo(row)">科目详情</div>
          <div class="text_btn_primary opertion_font"
               @click="toList(row)">试卷列表</div>
        </template>
      </el-table-column>
    </el-table>
    <Shuttle ref="ShuttleRef" />

    <div style="font-size:14px">
      说明：1. 总提交试卷数 = 整卷测试数 + 精选试卷数 + 自由选题数 + 考点训练数 + 章节训练数
    </div>
    <pagination ref="pagination"
                :get-data="initData"
                :isInit="false"
                :now-page.sync="page"
                :now-size.sync="size"
                :total="totalElements" />
  </div>
</template>

<script>
import { nowDateTime, frontDay } from '@/utils/date.js'
export default {
  name: 'studentStatistics',
  data () {
    return {
      page: 1,
      size: 10,
      totalElements: 0,
      jieList: [],
      school_id: '',
      correctType: {
        1: '平台改卷',
        2: '自评',
        3: '本校改卷',
        4: '本校改卷,超时释放'
      },
      form: {
        school_id: '',
        special_class_ids: '',
        name_or_mobile: '',
        graduated_id: '',
        correct_type: '',
        timeBegin: frontDay(7),
        timeEnd: nowDateTime(),
        page: 1,
        limit: 10,
        user_type: ''
      },
      tableData: [],
      schoolList: [],
      gradeList: [],
      classList: [],
      sums: [],
      userTypeList: [],
      home_id: 0,
      correctTypeList: [
        { value: 1, label: '平台改卷' },
        { value: 2, label: '学生自评' },
        { value: 3, label: '本校改卷' },
        { value: 4, label: '本校改卷(释放平台)' },

      ],
      showUserTypeSelect: true
    }
  },
  created () {
    this.school_id = JSON.parse(window.localStorage.getItem('userInfo')).edu_school_id
    var companySchool = [268, 269, 270]
    if (companySchool.indexOf(this.school_id) != -1) {
      this.form.user_type = 1
      this.showUserTypeSelect = false
    }
  },
  mounted () {
    this.getRole()
    this.getUserType()
    this.getgradeList()
  },
  methods: {
    getUserType () {
      this.$http({
        url: '/api/v1/public/user_type',
        method: 'get',
      }).then(res => {
        this.userTypeList = res.data
      })
    },
    getRole () {
      this.$http({
        url: '/api/v1/public/homepage_role',
        method: 'get',
      }).then(res => {
        this.home_id = res.data.home_id
        this.getSchoolList()
      })
    },
    // 统计行
    getSummaries () {
      const sums = [];
      sums[0] = '所有学生合计'
      sums[1] = `学生数${this.totalElements}`
      sums[2] = ''
      sums[3] = ''
      sums[4] = ''

      sums[5] = this.sums.count_all

      sums[6] = this.sums.zhengjuan_count

      sums[7] = this.sums.knowledge_count

      sums[8] = this.sums.teacher_count

      sums[9] = this.sums.count_submitted_with_image

      sums[10] = this.sums.count_checked

      sums[11] = this.sums.free_count
      sums[12] = this.sums.chapter_count

      sums[13] = this.sums.ave

      sums[14] = this.sums.userScoreRate

      sums[15] = ''

      return sums;
    },
    schoolChange () {
      this.form.special_class_ids = ''
      this.form.grade_id = ''
      this.gradeList = []
      this.userTypeList = []
      this.form.user_type = ''
      // this.getClassList()
    },
    initData (page, limit) {
      this.tableData = []

      let form = JSON.parse(JSON.stringify(this.form))
      form.page = page
      form.limit = limit
      this.$http({
        url: '/api/v1/student/index2',
        method: 'get',
        params: form
      }).then(res => {
        this.sums = res.data.sums
        this.tableData = res.data.list
        this.totalElements = res.data.count
      })
    },
    getSchoolList () {
      this.$http({
        url: 'api/v1/main/get_user_school',
        method: 'get'
      }).then(res => {
        this.schoolList = res.data.list
        if (this.school_id != 1) {
          this.form.school_id = this.schoolList[0].school_id
        }
        if (this.home_id != 1) {
          this.form.school_id = this.school_id
        }
        this.initData()
        this.getJie()
      })
    },
    getJie () {
      this.$http({
        url: '/api/v1/public/graduated_lst',
        method: 'get'
      }).then(res => {
        this.jieList = Object.values(res.data)
        // if (this.school_id != 1) {
        //   // this.form.graduated_id = this.jieList[0].graduated_id
        //   this.getClassList()
        // }
      })
    },
    getClassList () {
      // this.classList = []
      if (this.form.special_class_ids) {
        this.form.special_class_ids = undefined
      }

      this.$http({
        url: '/api/v1/special/tc_lead_class',
        method: 'get',
        params: {
          all_class: 1,
          graduated_id: this.form.graduated_id,
          grade_id: this.form.grade_id,
          school_id: this.form.school_id
        }
      }).then(res => {
        this.classList = res.data
      })
    },
    getgradeList () {
      //年级
        this.$http({
        url: '/api/v1/school/select_grade',
        method: 'get'
      }).then(res => {
        this.gradeList = res.data.list
      })
      // this.$http({
      //   url: '/api/v1/school/get_grade',
      //   method: 'get',
      // }).then(res => {
      //   // 当学校的值改变的时候,所有的数组和值都将清空,并赋初值
      //   this.form.grade_id = 0
      //   for (var key in res.data.grade_names) {
      //     this.gradeList.push(res.data.grade_names[key])
      //   }
      //   this.userTypeList = [{ user_type_id: '', user_type_name: '所有' }, ...res.data.user_types]
      // })
    },

    toList (row) {
      let form = {
        user_id: row.id,
        timeBegin: this.form.timeBegin,
        timeEnd: this.form.timeEnd,
      }
      this.$router.push('/studentCondition/statistics/list?queryInfo=' + JSON.stringify(form))
    },
    toInfo (row) {
      let form = {
        user_id: row.id,
        timeBegin: this.form.timeBegin,
        timeEnd: this.form.timeEnd,
      }
      this.$router.push('/studentCondition/statistics/subject?form=' + JSON.stringify(form))
    },
    // 刷新
    refresh () {
      this.$refs.pagination.shuaxin()
    },
    // 查询
    search () {
      this.$refs.pagination.toFirstPage()
    },
    exportExcel () {

      let form = JSON.parse(JSON.stringify(this.form))
      form.page = this.page
      form.limit = this.limit
      form.submit = 'export'
      form.special_class_ids = form.special_class_ids
      form.user_type = form.user_type
      this.$http({
        url: '/api/v1/student/export_stat',
        responseType: "blob",
        method: 'get',
        params: form
      }).then(res => {
        let valueList = Object.values(this.schoolList)
        let schoolName = ''
        let timeBegin = []
        let timeEnd = []
        if (this.form.school_id) {
          let school = valueList.find(item => item.school_id == this.form.school_id)
          if (school) {
            schoolName = school.school_name
          }
        }
        if (this.form.timeBegin) {
          timeBegin = this.form.timeBegin.slice(0, 10).split('-')
          timeBegin[0] = timeBegin[0] + '年'
          timeBegin[1] = timeBegin[1] + '月'
          timeBegin[2] = timeBegin[2] + '日'
        }
        if (this.form.timeEnd) {
          timeEnd = this.form.timeEnd.slice(0, 10).split('-')
          timeEnd[0] = timeEnd[0] + '年'
          timeEnd[1] = timeEnd[1] + '月'
          timeEnd[2] = timeEnd[2] + '日'
        }
        timeBegin = timeBegin.join('')
        timeEnd = timeEnd.join('')
        const a = document.createElement("a")
        a.href = window.URL.createObjectURL(res.data)
        // 2020年11月16日-2021年2月2日广州75中精准备考系统使用情况统计
        a.download = `${timeBegin}-${timeEnd}${schoolName}精准备考系统使用情况统计.xlsx`
        a.click()
      })
    },
    // getUserType () {
    //   this.$http({
    //     url: '/api/v1/school/get_user_type_data',
    //     method: 'get'
    //   }).then(res => {
    //     this.userTypeList = res.data.list
    //   })
    // }

  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-table__expanded-cell[class*="cell"] {
  padding: 0;
}
</style>